import styled from "styled-components";
import { useDataContext } from "../state";
import { theme } from "../theme";
import { animateScroll as scroll } from "react-scroll";
import { BsChevronUp } from "react-icons/bs";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

export const Footer = () => {
  const { resumeData } = useDataContext();
  return (
    <StyledFooter>
      <div className="row">
        <div className="twelve columns">
          <ul className="social-links">
            <li>
              <a
                title="LinkedIn profile"
                rel="noopener noreferrer"
                target="_blank"
                href={resumeData.socialMedia.linkedIn}
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a
                title="Github Profile"
                rel="noopener noreferrer"
                target="_blank"
                href={resumeData.socialMedia.gitHub}
              >
                <FaGithub />
              </a>
            </li>
          </ul>
        </div>
        <div id="go-top">
          <a
            title="Back to Top"
            href="#home"
            onClick={() => scroll.scrollToTop()}
          >
            <BsChevronUp />
          </a>
        </div>
      </div>
    </StyledFooter>
  );
};

const StyledFooter = styled.footer`
  padding-top: 72px;
  background: ${theme.background.darkBlue};
  padding-bottom: 48px;
  margin-bottom: 0px;
  #got-top {
    a {
      background-color: ${theme.background.darkPurple}!important;
    }
  }
`;
