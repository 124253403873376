import styled from "styled-components";
import { theme } from "../../theme";
import { resumeData } from "../../resumeData";
import { useScreen } from "../../hooks";

interface Props {}

export const AboutMe: React.FC<Props> = () => {
  const { isSmallTabletOrMobile } = useScreen();
  return (
    <StyledAboutMe $isSmallTabletOrMobile={isSmallTabletOrMobile}>
      <div>
        <h1>Who am I?</h1>
        <p>{resumeData.aboutMe}</p>
      </div>
      {/* <img
        className="profile-pic"
        src="images/hackaton.jpeg"
        alt="profile-pic"
      /> */}
    </StyledAboutMe>
  );
};

const StyledAboutMe = styled.section<{ $isSmallTabletOrMobile: boolean }>`
  font-family: "Poppins", serif;
  background-color: ${theme.background.darkBlue};
  color: ${theme.text.contrastText};
  text-align: center;
  h1 {
    letter-spacing: 2px;
    margin-bottom: 48px;
    font-weight: 600;
  }
  padding: 72px 0px;
  p {
    max-width: 800px;
    margin: auto;
    font-size: 16px;
    padding: 24px;
  }
`;
