import React, { useState } from "react";
import { ContactFormProvider, useContactFormContext } from "../../state";
import emailjs from "@emailjs/browser";
import { emailjsConfig } from "../../config";
import { ContactForm } from "./ContactForm";
import styled from "styled-components";
import { theme } from "../../theme";
import { Col, Row, notification } from "antd";
import { useScreen } from "../../hooks";

export const Contact = React.forwardRef((_, ref) => {
  return (
    <ContactFormProvider>
      <ContactContent ref={ref} />
    </ContactFormProvider>
  );
});

const ContactContent = React.forwardRef((_, ref) => {
  const { formData, isFormValid, initFormValues } = useContactFormContext();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { isSmallTabletOrMobile } = useScreen();

  const [api, contextHolder] = notification.useNotification();

  const showError = (message: string) => {
    api.error({
      message: message,
      placement: "bottomLeft",
    });
  };

  const showSuccess = () => {
    api.success({
      message: "Your message was sent, thank you!",
      placement: "bottomLeft",
    });
  };

  const handleSubmit = () => {
    if (!isFormValid) {
      showError("Please complete the required fields!");
      return;
    }
    sendEmail();
  };

  const onSubmitSuccess = () => {
    setIsSubmitting(false);
    showSuccess();
  };

  const onSubmitFail = () => {
    setIsSubmitting(false);
    showError("There was an error sending the message! Try again shortly.");
  };

  const { publicKey, serviceId, templateId } = emailjsConfig;

  const sendEmail = () => {
    setIsSubmitting(true);
    emailjs.send(serviceId, templateId, formData as any, publicKey).then(
      (result) => {
        onSubmitSuccess();
        console.log(result);
        initFormValues();
      },
      (error) => {
        onSubmitFail();
        console.error(error);
      }
    );
  };

  return (
    <StyledContactSection
      $isSmallTabletOrMobile={isSmallTabletOrMobile}
      ref={ref as any}
      id="contact"
    >
      {contextHolder}
      <Row gutter={48}>
        {!isSmallTabletOrMobile && (
          <Col span={10}>
            <img src="images/msg.gif" alt="paper-plane-gif" />
          </Col>
        )}
        <Col span={isSmallTabletOrMobile ? 24 : 14}>
          <StyledFormWrapper $isSmallTabletOrMobile={isSmallTabletOrMobile}>
            <h1>Want to get in touch?</h1>
            <ContactForm isSubmitting={isSubmitting} onSubmit={handleSubmit} />
          </StyledFormWrapper>
        </Col>
      </Row>
    </StyledContactSection>
  );
});

const StyledFormWrapper = styled.div<{
  $isSmallTabletOrMobile: boolean;
}>`
  padding: ${({ $isSmallTabletOrMobile }) =>
    $isSmallTabletOrMobile ? "32px 24px" : "56px 72px 72px"};
  background-color: #fff;
  border-radius: 32px;
`;

const StyledContactSection = styled.section<{
  $isSmallTabletOrMobile: boolean;
}>`
  img {
    height: 100%;
  }
  background: ${theme.background.darkBlue};
  color: ${theme.text.contrastText};
  text-align: left;
  padding: ${({ $isSmallTabletOrMobile }) =>
    $isSmallTabletOrMobile ? `48px 24px 112px 24px` : "72px 48px 148px 48px"};

  a,
  a:visited {
    color: #11abb0;
  }

  a:hover,
  a:focus {
    color: #fff;
  }

  h1 {
    font-size: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "30px" : "42px"};
    font-family: "Poppins", serif;
    font-weight: 600;
    line-height: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "40px" : "58px"};
    color: ${theme.background.darkBlue};
    text-align: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? `center` : "left"};
  }
`;
