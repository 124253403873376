import React, { useContext, useState } from "react";

interface FormData {
  contactEmail: string;
  contactName: string;
  subject: string;
  message: string;
}

interface ContactFormContextProps {
  formData: FormData;
  updateFormValues: (
    key: keyof FormData,
    value: FormData[keyof FormData]
  ) => void;
  isFormValid: boolean;
  initFormValues: () => void;
}

const initialContactFormValues: FormData = {
  contactEmail: "",
  contactName: "",
  message: "",
  subject: "",
};

// Short circuit ahead, this will never be empty
const ContactFormContext = React.createContext<ContactFormContextProps>(
  {} as ContactFormContextProps
);

export const useContactFormContext = () => useContext(ContactFormContext);

export const ContactFormProvider = ({ children }: React.PropsWithChildren) => {
  const [formValues, setFormValues] = useState(initialContactFormValues);
  const { contactEmail, contactName, message } = formValues;

  const updateFormValues: ContactFormContextProps["updateFormValues"] = (
    key,
    value
  ) => {
    setFormValues((prev) => ({ ...prev, [key]: value }));
  };

  const initFormValues = () => setFormValues(initialContactFormValues);

  const isFormValid =
    !isEmptyString(contactName) &&
    !isEmptyString(contactEmail) &&
    !isEmptyString(message);

  const value = {
    formData: formValues,
    updateFormValues,
    isFormValid,
    initFormValues,
  };

  return (
    <ContactFormContext.Provider value={value}>
      {children}
    </ContactFormContext.Provider>
  );
};

const isEmptyString = (s: string) => !Boolean(s.trim());
