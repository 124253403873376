import styled from "styled-components";
import { theme } from "../../theme";
import { Carousel } from "./Carousel";
import React from "react";

export const Experience = React.forwardRef((_, ref) => {
  return (
    <StyledExperience ref={ref as any} id="works">
      <div>
        <h1>What are my Contributions?</h1>
        <Carousel />
      </div>
    </StyledExperience>
  );
});

const StyledExperience = styled.section`
  font-family: "Poppins", serif !important;
  background-color: ${theme.background.darkBlue};
  h1 {
    padding-top: 120px;
    padding-bottom: 80px;
    font-weight: 600;
    margin: 0px;
    color: ${theme.text.contrastText};
  }
  height: 100vh;
  > div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
`;
