import { FormEvent } from "react";
import styled from "styled-components";
import { useContactFormContext } from "../../state";
import { theme } from "../../theme";
import { useScreen } from "../../hooks";
import { FiSend } from "react-icons/fi";
import { Button } from "antd";

interface Props {
  onSubmit: () => void;
  isSubmitting: boolean;
}

export const ContactForm: React.FC<Props> = ({ onSubmit, isSubmitting }) => {
  const { formData, updateFormValues } = useContactFormContext();
  const { contactEmail, contactName, message, subject } = formData;
  const { isSmallTabletOrMobile } = useScreen();

  const handleOnSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <StyledContactForm $isSmallTabletOrMobile={isSmallTabletOrMobile}>
      <form onSubmit={handleOnSubmit} id="contactForm" name="contactForm">
        <fieldset>
          <div>
            <label htmlFor="contactName">
              Name <span className="required">*</span>
            </label>
            <input
              type="text"
              value={contactName}
              onChange={(e) => updateFormValues("contactName", e.target.value)}
              size={35}
              id="contactName"
              name="contactName"
            />
          </div>
          <div>
            <label htmlFor="contactEmail">
              Email <span className="required">*</span>
            </label>
            <input
              type="text"
              value={contactEmail}
              onChange={(e) => updateFormValues("contactEmail", e.target.value)}
              size={35}
              id="contactEmail"
              name="contactEmail"
            />
          </div>
          <div>
            <label htmlFor="subject">Subject</label>
            <input
              type="text"
              value={subject}
              onChange={(e) => updateFormValues("subject", e.target.value)}
              size={35}
              id="subject"
              name="subject"
            />
          </div>
          <div>
            <label htmlFor="message">
              Message <span className="required">*</span>
            </label>
            <textarea
              onChange={(e) => updateFormValues("message", e.target.value)}
              id="message"
              name="message"
              value={message}
            />
          </div>
          <div>
            <Button
              size="large"
              htmlType="submit"
              loading={isSubmitting}
              className="submit"
            >
              <FiSend />
              Send Message
            </Button>
          </div>
        </fieldset>
      </form>
    </StyledContactForm>
  );
};

const StyledContactForm = styled.div<{ $isSmallTabletOrMobile: boolean }>`
  label {
    margin: 12px 0px 6px 0px;
    color: ${theme.background.darkBlue};
    display: block;
    font-weight: 500;
  }

  input,
  textarea,
  select {
    padding: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "12px 12px" : "18px 20px"};
    background: #fff;
    border: 2px solid ${theme.background.darkBlue}!important;
    /* margin-bottom: 24px; */
    border: 0;
    outline: none;
    font-size: 15px;
    border-radius: 12px;
    width: 100%;
  }

  button.submit {
    font-family: "Poppins", serif;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #fff;
    background: ${theme.background.darkPurple};
    border: none;
    cursor: pointer;
    height: auto;
    display: inline-block;
    border-radius: 48px;
    padding: 10px 24px;
    width: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "100%" : ""};
    margin-top: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "16px" : "24px"};
    margin-bottom: 0px;
    font-weight: 600;
    transition: 0.3s;
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
  }

  button.submit:hover {
    opacity: 0.95;
    transform: scale(1.01);
    color: #fff;
    /* color: #0d0d0d; */
    /* background: #fff; */
  }

  span.required {
    color: ${theme.background.darkBlue};
    font-size: 13px;
  }
`;
