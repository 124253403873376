import React, { useContext } from "react";
import { ResumeDataType } from "../resumeData";
import { resumeData } from "../resumeData";

interface DataContextProps {
  resumeData: ResumeDataType;
}

// short circuit ahead, this will never be empty
const DataContext = React.createContext<DataContextProps>(
  {} as DataContextProps
);

export const useDataContext = () => useContext(DataContext);

export const DataProvider = ({ children }: React.PropsWithChildren) => {
  const value = {
    resumeData,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
