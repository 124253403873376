import "./App.css";
import {
  Header,
  Quote,
  Contact,
  Footer,
  Experience,
  AboutMe,
} from "../sections";
import { DataProvider } from "../state";
import { useScrollPosition } from "../hooks";
import { useEffect, useRef, useState } from "react";
import styled from "styled-components";

export const App = () => {
  const scrollPosition = useScrollPosition();
  const worksRef = useRef<HTMLElement>();
  const aboutRef = useRef<HTMLElement>();
  const contactRef = useRef<HTMLElement>();
  const [current, setCurrent] = useState("home");
  const showBackgroundNav = worksRef.current?.offsetTop
    ? worksRef.current?.offsetTop <= scrollPosition
    : false;

  useEffect(() => {
    if (scrollPosition < (worksRef?.current?.offsetTop || 0)) {
      setCurrent("home");
    }
    if (scrollPosition >= (worksRef?.current?.offsetTop || 0) - 50) {
      setCurrent("works");
    }
    if (scrollPosition >= (aboutRef?.current?.offsetTop || 0) - 50) {
      setCurrent("about");
    }
    if (scrollPosition >= (contactRef?.current?.offsetTop || 0) - 50) {
      setCurrent("contact");
    }
  }, [scrollPosition]);

  return (
    <div className="App">
      <DataProvider>
        <Header showBackground={showBackgroundNav} current={current} />
        <Experience ref={worksRef} />
        <StyledAboutMeContainer>
          <Quote ref={aboutRef} />
          <AboutMe />
        </StyledAboutMeContainer>
        <Contact ref={contactRef} />
        <Footer />
      </DataProvider>
    </div>
  );
};

const StyledAboutMeContainer = styled.div`
  min-height: 100vh;
  section {
    min-height: 50vh;
  }
`;
