export const theme = {
  background: {
    darkBlue: "#1C1B2E",
    darkPurple: "#67257A",
  },
  text: {
    contrastText: "rgba(255,255,255,0.8)",
  },
};

const size = {
  mobileS: "320px",
  mobileM: "480px",
  mobileL: "760px",
  tablet: "960px",
  laptop: "1200px",
  laptopL: "1600px",
  desktop: "2560px",
};

export const device = {
  mobileS: `max-width: ${size.mobileS}`,
  mobileM: `max-width: ${size.mobileM}`,
  mobileL: `max-width: ${size.mobileL}`,
  tablet: `max-width: ${size.tablet}`,
  laptop: `max-width: ${size.laptop}`,
  laptopL: `max-width: ${size.laptopL}`,
  desktop: `max-width: ${size.desktop}`,
  desktopL: `max-width: ${size.desktop}`,
};
