import { Swiper, SwiperSlide } from "swiper/react";
import { A11y, Pagination } from "swiper";
import styled from "styled-components";

// Import Swiper styles
import "swiper/css/bundle";
import { ProjectCard } from "./ProjectCard";
import { useDataContext } from "../../state";
import { useState } from "react";

export const Carousel: React.FC = () => {
  const { resumeData } = useDataContext();
  const { projects } = resumeData;
  const [activeItem, setActiveItem] = useState(0);

  const extraStyledIndex = activeItem + 2;
  return (
    <StyledCarousel>
      <Swiper
        modules={[A11y, Pagination]}
        slidesPerView="auto"
        pagination={{
          el: ".pagination",
          clickable: true,
          renderBullet: (index, className) => {
            return '<span class="' + className + '">  </span>';
          },
        }}
        centeredSlides
        onSlideChange={(e) => setActiveItem(e.activeIndex)}
      >
        {projects.map((project, i) => (
          <SwiperSlide
            style={
              extraStyledIndex === i
                ? {
                    transform: "translate(-36px) scale(0.7)",
                  }
                : {}
            }
            key={i}
          >
            <ProjectCard
              isActive={activeItem === i}
              isNext={activeItem < i}
              project={project}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      <div className="pagination" />
    </StyledCarousel>
  );
};

const StyledCarousel = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  --swiper-image-ratio: 33.3%; /* ratio 16:9 = 56.25% */

  --swiper-width: 30%;
  --swiper-inactive-scale: 0.85; /* makes the other slide smaller */

  /* responsive settings */
  --swiper-mobile-width: 90%;
  --swiper-mobile-inactive-scale: 0.95;

  .swiper-slide {
    position: relative;
    width: var(--swiper-width);
    opacity: 0.5;
    transform: scale(0.7);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    border-radius: 0.4285rem;
  }

  .swiper-slide.swiper-slide-active {
    transform: scale(1) !important;
    opacity: 1 !important;
    /* margin-right: 16px; */
    /* margin-left: 16px; */
  }

  .swiper-button-next,
  .swiper-button-prev {
    padding: 8px;
    width: 24px;
    height: 24px;
    margin-top: 0;

    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 50%;
  }

  .swiper-button-next::after,
  .swiper-button-prev::after {
    color: #1b0e2d;
    font-size: 16px;
  }

  .swiper-button-next {
    /* transform: translate(50%, -50%);
    right: calc((100% - var(--swiper-width)) / 2.1); */
  }

  .swiper-button-prev {
    /* transform: translate(-50%, -50%);
    left: calc((100% - var(--swiper-width)) / 2.1); */
  }

  .swiper-pagination-bullet-active {
    background-color: #fff;
  }
  .swiper-pagination-bullet:not(.swiper-pagination-bullet-active) {
    background-color: gray;
  }

  @media only screen and (max-width: 768px) {
    .swiper {
      height: calc(
        var(--swiper-mobile-width) * var(--swiper-image-ratio) / 100%
      );
    }

    .swiper-slide {
      width: var(--swiper-mobile-width);
      transform: scale(var(--swiper-mobile-inactive-scale));
    }

    .swiper-backface-hidden .swiper-slide.swiper-slide {
      transform: scale(var(--swiper-mobile-inactive-scale)) translateZ(0);
    }

    .swiper-button-next {
      right: calc((100% - var(--swiper-mobile-width)) / 2);
    }

    .swiper-button-prev {
      left: calc((100% - var(--swiper-mobile-width)) / 2);
    }
  }
  .pagination {
    margin-top: 24px;
  }
  padding-bottom: 36px;
  /* max-width: 90vw; */
  margin: auto;
  .swiper-button-next,
  .swiper-button-prev {
    color: #1b0e2d;
  }
`;
