import { useMediaQuery } from "react-responsive";

export const useScreen = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-width: 1824px)" });
  const isSmallTabletOrMobile = useMediaQuery({ query: "(max-width: 790px)" });
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  return { isDesktopOrLaptop, isBigScreen, isSmallTabletOrMobile, isPortrait };
};
