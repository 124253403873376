export const resumeData = {
  name: "Ahmed Bendaya",
  aboutMe:
    "Hi, I'm Ahmed Bendaya, and I'm a frontend engineer. I specialize in creating engaging and intuitive user interfaces for websites and applications. With a deep understanding of frontend, I work closely with teams to turn a concept into functional and visually stunning product. I am experienced in using a variety of front-end frameworks and libraries such as React to build scalable, responsive, and performant user interfaces.",
  roles: {
    role1: "Software Engineer",
    role2: "Frontend Lead",
  },
  currentCompany: {
    name: "SwiftConnect",
    url: "https://swiftconnect.io",
  },
  socialMedia: {
    gitHub: "https://github.com/hiimdjango",
    linkedIn: "https://www.linkedin.com/in/ahmed-bendaya-0a3374136/",
  },
  address: {
    line1: "Montréal, Quebec, Canada",
    line2: "",
  },
  phone: "(438)993-2818",
  email: "ahmedbendaya@outlook.com",
  profilePic: "images/a-bendaya.jpg",
  resume: "files/Resume.pdf",
  projects: [
    {
      title: "Finances",
      description:
        "Viktoria App is a budget management app for end users to help make better financial decisions. ",
      img: "images/works/viktoria.jpg",
      link: "https://viktoria.app",
    },
    {
      title: "Revenue Management",
      description:
        "Appia is a web app made by Expretio to help optimize revenues for transport companies.",
      img: "images/works/appia.jpg",
      link: "https://www.expretio.com/appia",
    },
    {
      title: "Physical Access Control",
      description:
        "SwiftConnect connects people to the right place at the right time, through mobile credentials.",
      img: "images/works/swiftconnect.jpg",
      link: "https://swiftconnect.io",
    },
    {
      title: "Medical",
      description:
        "Docmind is a fully responsive web app that helps doctors manage their workspace and patients.",
      img: "images/works/docmind.jpg",
      link: "https://docmind.app",
    },
    {
      title: "Digital Marketing",
      description:
        "IDB is a web app that helps automating ad campaigns for car dealerships and optimize conversion.",
      img: "images/works/dealerbreacher.jpg",
      link: "https://dealerbreacher.com",
    },
  ],
  skills: [
    { name: "Front-End Development", percentage: "80%" },
    { name: "Motivation", percentage: "100%" },
    { name: "Agile Development", percentage: "80%" },
    { name: "Teamwork", percentage: "100%" },
    { name: "Object Oriented Programming", percentage: "65%" },
    { name: "Fast Learning", percentage: "100%" },
  ],
  portfolio: [
    {
      title: "Medical Tourism App",
      img: "images/portfolio/treatyourself.jpg",
      details: "https://github.com/hiimdjango/React-treat-yourself",
      modalImg: "images/portfolio/modals/m-treatyourself.png",
      tags: "React.js, Web Design",
      description:
        "A Medical Tourism home page with a dynamic slide created with React and Reactstrap.",
    },
    {
      title: "Cordonnerie",
      img: "images/portfolio/cordonnerie.jpg",
      details: "https://github.com/hiimdjango/Cordonnerie-du-village",
      modalImg: "images/portfolio/modals/m-cordonnerie.png",
      tags: "HTML/CSS, Web Design",
      description: "Shoemaker business simple home page with HTML/CSS only.",
    },
    {
      title: "Login",
      img: "images/portfolio/login.png",
      details: "https://github.com/hiimdjango/React-Login",
      modalImg: "images/portfolio/modals/m-login.jpg",
      tags: "React.js, Firebase",
      description:
        "A Perfectly working login component set up with Firebase and ready to be used.",
    },
    {
      title: "Blog",
      img: "images/portfolio/blog.jpg",
      details: "https://github.com/hiimdjango/React-3Projects/tree/master/blog",
      modalImg: "images/portfolio/modals/m-blog.jpg",
      tags: "React.js, Bootstrap",
      description:
        "A Blog template with Bootstrap turned into React Components. One of my first projects learning React.",
    },
    {
      title: "Video Making",
      img: "images/portfolio/retrocam.jpg",
      details: "https://www.youtube.com/watch?v=wG7Z868UNdI",
      modalImg: "images/portfolio/modals/m-jackjones.png",
      tags: "Video Making, Jack & Jones",
      description:
        "A video made for Jack & Jones (Saint Bruno) at the Denim Tour event with Adobe Premiere Pro and a DSLR camera, click on details for the video-link.",
    },
    {
      title: "Burger Builder",
      img: "images/portfolio/burger.jpg",
      details: "https://github.com/hiimdjango/React-BurgerBuilder",
      modalImg: "images/portfolio/modals/m-burger.jpg",
      tags: "React.JS",
      description:
        "A Cool burger builder single web application made with React.js, check the source code in GutHub, work stil in progress.",
    },
    {
      title: "Furniture Web App",
      img: "images/portfolio/furniture.jpg",
      details: "https://github.com/hiimdjango/Furniture-Website",
      modalImg: "images/portfolio/modals/m-furniture.jpg",
      tags: "JavaScript Vanilla, CSS, HTML, JSON",
      description:
        "A fully working furniture business Web App, no framework was used, only pure JavaScript, HTML and CSS, check the source code by clicking on details.",
    },
  ],
};

export type ResumeDataType = typeof resumeData;
