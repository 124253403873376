import { useDataContext } from "../state";
import styled from "styled-components";
import { Link } from "react-scroll";
import { BsChevronDown } from "react-icons/bs";
import { device } from "../theme";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

interface Props {
  current?: string;
  showBackground: boolean;
}

export const Header = ({ current, showBackground }: Props) => {
  const { resumeData } = useDataContext();
  return (
    <StyledHeader id="home">
      <StyledNav $showBackground={showBackground}>
        <ul>
          <li className={current === "home" ? "current" : ""}>
            <Link to="home" spy={true} smooth={true} duration={500}>
              Home
            </Link>
          </li>
          <li className={current === "works" ? "current" : ""}>
            <Link to="works" spy={true} smooth={true} duration={500}>
              Works
            </Link>
          </li>
          <li className={current === "about" ? "current" : ""}>
            <Link to="about" spy={true} smooth={true} duration={500}>
              About
            </Link>
          </li>
          <li className={current === "contact" ? "current" : ""}>
            <Link to="contact" spy={true} smooth={true} duration={500}>
              Contact
            </Link>
          </li>
        </ul>
      </StyledNav>
      <video playsInline autoPlay muted loop id="myVideo">
        <source src="images/h-video.mp4" type="video/mp4" />
      </video>
      <StyledBanner>
        <StyledBannerText className="banner-text">
          <h1>Hello World</h1>
          <h1>I'm {resumeData.name}.</h1>
          <h3>
            <span>{resumeData.roles.role1}</span> and{" "}
            <span>{resumeData.roles.role2}</span> at{" "}
            <a
              href={resumeData?.currentCompany.url}
              rel="noreferrer"
              target="_blank"
            >
              {resumeData.currentCompany.name}
            </a>
            .
          </h3>
          <hr />
          <ul className="social">
            <li>
              <a
                title="LinkedIn profile"
                rel="noopener noreferrer"
                target="_blank"
                href={resumeData.socialMedia.linkedIn}
              >
                <FaLinkedinIn />
              </a>
            </li>
            <li>
              <a
                title="Github Profile"
                rel="noopener noreferrer"
                target="_blank"
                href={resumeData.socialMedia.gitHub}
              >
                <FaGithub />
              </a>
            </li>
          </ul>
        </StyledBannerText>
      </StyledBanner>
      <p className="scrolldown">
        <Link smooth title="Works" to="works" duration={500}>
          <BsChevronDown />
        </Link>
      </p>
    </StyledHeader>
  );
};

const StyledHeader = styled.header`
  position: relative;
  height: 100vh;
  width: 100%;
  -webkit-background-size: cover !important;
  background-size: cover !important;
  text-align: center;
  overflow: hidden;
  :before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
  video {
    position: absolute;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
  .scrolldown {
    a {
      :hover {
        cursor: pointer;
      }
    }
    svg {
      font-size: 24px;
    }
  }
  .social {
    margin: 24px 0;
    padding: 0;
    font-size: 30px;
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.8);
  }

  .social li {
    display: inline-block;
    margin: 0 15px;
    padding: 0;
  }

  .social li a {
    color: #fff;
  }

  .social li a:hover {
    color: #11abb0;
  }
`;

const StyledBanner = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  color: #f1f1f1;
  width: 100%;
  padding: 20px;
`;

const StyledBannerText = styled.div`
  width: 100%;

  h1:first-of-type {
    margin-bottom: 24px;
  }

  h1 {
    font: 72px "Poppins", serif;
    font-weight: 600;
    letter-spacing: 2px;
    color: #fff;
  }

  h1:first-of-type {
    padding-top: 120px;
  }

  h1:nth-of-type(2) {
    font-size: 28px;
  }

  h3 {
    font: 18px/1.9em "Poppins", serif;
    color: #a8a8a8;
    margin: 0 auto;
    width: 70%;
  }

  h3 span,
  h3 a {
    color: #fff;
  }

  hr {
    width: 60%;
    margin: 18px auto 24px auto;
    border-color: #2f2d2e;
    border-color: rgba(150, 150, 150, 0.1);
  }

  @media only screen and (${device.mobileL}) {
    h1 {
      font: 48px "Poppins", serif;
      font-weight: 600;
    }
    h1:nth-of-type(2) {
      font-size: 18px;
    }
    h3 {
      font: 12px/1.9em "Poppins", serif;
      color: #a8a8a8;
      margin: 0 auto;
      width: 70%;
    }
  }
`;

const StyledNav = styled.nav<{ $showBackground: boolean }>`
  font: 12px "opensans-bold", sans-serif;
  width: 100%;
  text-transform: uppercase;
  letter-spacing: 2.5px;
  margin: 0 auto;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  ul,
  li,
  a {
    margin: 0;
    padding: 0;
    border: none;
    outline: none;
  }

  a:hover {
    cursor: pointer;
  }

  ul {
    min-height: 48px;
    width: auto;

    /* center align the menu */
    text-align: center;
  }

  ul li {
    position: relative;
    list-style: none;
    height: 48px;
    display: inline-block;
  }

  /* Links */
  ul li a {
    /* 8px padding top + 8px padding bottom + 32px line-height = 48px */

    display: inline-block;
    padding: 8px 13px;
    line-height: 32px;
    text-decoration: none;
    text-align: left;
    color: #fff;

    -webkit-transition: color 0.2s ease-in-out;
    -moz-transition: color 0.2s ease-in-out;
    -o-transition: color 0.2s ease-in-out;
    -ms-transition: color 0.2s ease-in-out;
    transition: color 0.2s ease-in-out;
  }

  ul li a:active {
    background-color: transparent !important;
  }

  ul li.current a {
    text-transform: uppercase;
    background-image: linear-gradient(
      -225deg,
      #522fcf 0%,
      #fff800 29%,
      #ff1361 67%,
      #fff800 100%
    );
    background-size: auto auto;
    background-clip: border-box;
    background-size: 200% auto;
    color: #fff;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: textclip 2s linear infinite;
    display: inline-block;
    color: #e8630a;
  }

  @keyframes textclip {
    to {
      background-position: 200% center;
    }
  }
  background-color: ${({ $showBackground }) =>
    $showBackground ? "rgba(28, 27, 46, 1)" : "transparent"};
`;
