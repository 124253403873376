import styled from "styled-components";
import { theme } from "../../theme";
import { ResumeDataType } from "../../resumeData";
import { useSwiper } from "swiper/react";

interface Props {
  project: ResumeDataType["projects"][number];
  isNext: boolean;
  isActive: boolean;
}

export const ProjectCard: React.FC<Props> = ({ project, isNext, isActive }) => {
  const { description, img, title, link } = project;
  const swiper = useSwiper();
  return (
    <StyledProjectCard
      $isActive={isActive}
      onClick={() => {
        if (isNext) {
          swiper.slideNext();
        } else if (!isActive) {
          swiper.slidePrev();
        } else if (isActive) {
          window.open(link, "_blank");
        }
      }}
    >
      <img alt="project-illustration" src={img} />
      <h3>{title}</h3>
      <p>{description}</p>
    </StyledProjectCard>
  );
};

const StyledProjectCard = styled.div<{ $isActive: boolean }>`
  :hover {
    cursor: ${({ $isActive }) => ($isActive ? "pointer" : "")};
  }
  text-align: left;
  color: ${theme.text.contrastText};
  img {
    aspect-ratio: 4 / 3; /* typical video aspect ratio */
    object-fit: cover;
  }
  h3 {
    margin-top: 12px;
    margin-bottom: 4px;
    font-weight: 500;
    padding: 0px 24px 0px 0px;
  }
  img {
    border-radius: 32px;
  }
  p {
    padding: 0px 24px 0px 0px;
  }
`;
