import React from "react";
import { useDataContext } from "../../state";
import styled from "styled-components";
import { Col, Row } from "antd";
import { useScreen } from "../../hooks";
import { theme } from "../../theme";

export const Quote = React.forwardRef((_, ref) => {
  const { resumeData } = useDataContext();
  const { isSmallTabletOrMobile } = useScreen();
  const imgColProps = isSmallTabletOrMobile
    ? {
        span: 24,
      }
    : {
        flex: "180px",
      };
  return (
    <StyledQuoteSection id="about" ref={ref as any}>
      <Row justify="center" align="middle" gutter={16} wrap={true}>
        <Col {...imgColProps}>
          <img
            className="profile-pic"
            src={resumeData.profilePic}
            alt="profile-pic"
          />
        </Col>
        <StyledQuotesContainer
          $isSmallTabletOrMobile={isSmallTabletOrMobile}
          flex="auto"
        >
          <blockquote cite="ChatGPT">
            <i>
              Technology is a powerful tool that has transformed how we live,
              work, and solve problems. It has opened up opportunities for
              innovation and progress and will continue to shape our future.
            </i>
          </blockquote>
        </StyledQuotesContainer>
      </Row>
    </StyledQuoteSection>
  );
});

const StyledQuoteSection = styled.section`
  display: flex;
  justify-content: center;
  .ant-row {
    max-width: 900px;
    p {
      color: rgba(255, 255, 255, 0.8);
    }
  }
  background: ${theme.background.darkPurple};
  padding: 72px 24px;
  padding-top: 72px;
  padding-bottom: 66px;
  overflow: hidden;
  a,
  a:visited {
    color: #fff;
  }

  a:hover,
  a:focus {
    color: #11abb0;
  }

  .profile-pic {
    width: 120px;
    height: 120px;
    border-radius: 100%;
  }
`;

const StyledQuotesContainer = styled(Col)<{ $isSmallTabletOrMobile: boolean }>`
  h2 {
    color: #2b2b2b;
    font: 22px/30px "opensans-bold", sans-serif;
    margin-bottom: 12px;
  }
  blockquote {
    line-height: 28px;
    color: rgba(255, 255, 255, 0.8);
    font-size: ${({ $isSmallTabletOrMobile }) =>
      $isSmallTabletOrMobile ? "1.5rem" : "2.3rem"};
  }
`;
